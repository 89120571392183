@use "src/pages/company/ui/dataPointGenerator/fields/_styles/localMixins";


.fieldWrapper {
	display: flex;
	align-items: center;

	& > div:not(:last-child) {
		margin-right: 1rem;
	}
}

.select {
	width: var(--company-form-field-width);
	margin-right: 1rem;
}

.error {
	@include localMixins.error;
}

.searchBar {
	position: sticky;
	top: 1rem;
	z-index: var(--z-index-header);
	margin: .5rem 1rem;
	background-color: var(--color-common-white);

	.input {
		width: 100%;
		padding: .75rem;
	}
}