@use "src/app/styles/common/layout.module";

.container {
  cursor: pointer;
  display: flex;
  gap: 1.2rem;
  align-items: center
}

.menu {
  @include layout.card;

  position: fixed;
  top: 5rem;
  right: 1rem;
  padding: 1.5rem;
}
