.container {
  overflow: auto;
}

.title {
  margin-bottom: 1rem;
}

.error {
  margin-bottom: 1rem;
}

.errorCode {
  font-weight: 500;
  color: var(--error-main)
}
