@mixin field-wrapper {
  display: flex;
  align-items: center;
}

@mixin additional-text-left {
  margin-right: .5rem;
}

@mixin additional-text-right {
  margin-left: .5rem;
}

@mixin error {
  margin-top: .5rem;
  color: var(--error-main)
}
