@use "../../../../app/styles/common/typography.module";

.container {
	display: flex;
	flex-direction: column;
}

.label {
	display: flex;
	align-items: start;
	margin-bottom: .5rem;
}

.question {
	max-width: 32rem;

	&__text {
		margin-bottom: .5rem;
		color: var(--color-primary-main);
	}

	&__description {
		@include typography.caption;

		font-size: .8rem;
	}
}

.fields {
	display: flex;
	align-items: center;
	margin-bottom: 1.4rem;
}

.field {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	margin-right: 1.5rem;
	padding-left: 3.5rem;
}

.dataPoint {
	display: flex;
	align-items: center;

	& > * {
		margin-right: 2rem;
	}
}
