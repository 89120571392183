.container {
  cursor: pointer;

  width: 100%;
  padding: .25rem .75rem;

  font-size: .7rem;
  color: var(--color-grey-800);
  white-space: nowrap;

  background-color: var(--color-common-white);
  border: 2px solid var(--color-grey-300);
  border-radius: var(--border-radius);

  &_active {
    border: 2px solid var(--color-primary-light);
  }
}

.name {
  font-weight: bold;
}
