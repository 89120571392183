@use "src/pages/company/ui/dataPointGenerator/fields/_styles/localMixins";

.textField {
	width: 15rem;
	margin-right: 2rem;
}

.fieldWrapper {
	@include localMixins.field-wrapper;
}

.additionalTextRight {
	@include localMixins.additional-text-right;
}

.error {
	@include localMixins.error;
}
