@use "src/shared/styles/mediaQueries";

.cardsList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;

  @media (min-width: mediaQueries.$bp-desktop-small-min) and (max-width: mediaQueries.$bp-desktop-medium-max) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 0) and (max-width: mediaQueries.$bp-tablet-max) {
    grid-template-columns: 1fr;
  }
}
