.container {
  position: sticky;
  z-index: var(--z-index-header);
  top: 0;

  display: flex;
  justify-content: space-between;

  height: 8vh;
  padding: .8rem 2rem;

  background-color: var(--color-common-white);
  box-shadow: var(--shadow-2);
}

.left {
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 4rem;
  }
}

.logo {
  & img {
    width: 10rem;
    object-fit: contain;
  }

  & :hover {
    cursor: pointer;
  }
}
