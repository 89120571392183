@use "src/pages/company/ui/dataPointGenerator/fields/_styles/localMixins";

.container {
	display: flex;
	flex-direction: column;
}

.textField {
	width: 10rem;
}

.fieldWrapper {
	@include localMixins.field-wrapper;

	margin-right: 1rem;
}

.additionalTextLeft {
	@include localMixins.additional-text-left;
}

.error {
	@include localMixins.error;
}

