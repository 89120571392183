@use "src/app/styles/common/typography.module";

.label {
  @include typography.caption;
}

.select {
  width: 10rem;
  font-weight: bolder;
  border-radius: 1.5rem;
}
