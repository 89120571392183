@use "../_styles/localMixins";

.fieldWrapper {
  @include localMixins.field-wrapper;

  & > div:not(:last-child) {
    margin-right: 1rem;
  }
}

.textField {
  width: var(--company-form-field-width)
}

.additionalTextRight {
  @include localMixins.additional-text-right;
}

.error {
  @include localMixins.error;
}
