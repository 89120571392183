.margin {
  &_xs {
    margin-bottom: .5rem;
  }

  &_s {
    margin-bottom: 1rem;
  }

  &_m {
    margin-bottom: 1.5rem;
  }

  &_l {
    margin-bottom: 2rem;
  }

  &_xl {
    margin-bottom: 2.5rem;
  }
}
