@use "src/pages/company/ui/dataPointGenerator/fields/_styles/localMixins";

.select {
	width: 15rem;
}

.fieldWrapper {
	@include localMixins.field-wrapper;
}

.error {
	@include localMixins.error;
}
