@use "../../../app/styles/common/layout.module";

.container {
  @include layout.card-regular;

  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;

  height: 100%;
}

.footer {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.img {
  position: relative;
  right: -2.5rem;
  bottom: -2.5rem;

  width: 12rem;
  height: 12rem;
}
