.container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
}

.widget {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    margin-bottom: .5rem;
  }
}
