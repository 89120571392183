@use "src/app/styles/common/layout.module";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.paper {
  @include layout.paper-color;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: fit-content;
  padding: 2rem;

  box-shadow: var(--shadow-3);
}

.logo {
  margin-bottom: 2rem;

  & img {
    width: 200px;
  }
}
