@use "src/pages/company/ui/dataPointGenerator/fields/_styles/localMixins";

.fieldWrapper {
	@include localMixins.field-wrapper;

	margin-right: 1rem;
}

.additionalTextLeft {
	@include localMixins.additional-text-left;
}

.textField {
	width: 15rem;
}

.error {
	@include localMixins.error;
}
