@use "../_styles/localMixins";

.textField {
  width: 15rem;
  margin-right: 1rem;
}

.newValueWrapper {
  display: flex;
  align-items: center;

  & > div:not(:last-child) {
    margin-right: 1rem;
  }
}

.chipsWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: .5rem;

  & > span:not(:last-child) {
    margin-right: .5rem;
  }
}

.chip {
  margin-bottom: .5rem;
}

.fieldWrapper {
  @include localMixins.field-wrapper;
}

.additionalTextRight {
  @include localMixins.additional-text-right;
}

.error {
  @include localMixins.error;
}
