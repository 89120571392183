.container {
  display: inline-block;

  width: 1.5rem;
  min-width: 1.5rem;
  max-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  max-height: 1.5rem;

  & > svg {
    width: inherit;
    min-width: inherit;
    max-width: inherit;
    height: inherit;
    min-height: inherit;
    max-height: inherit;
  }
}
