@use "../../../app/styles/common/layout.module";
@use "src/shared/styles/mediaQueries";

.container {
  @include layout.card-thick;

  background-image: url('../../../shared/assets/img/globe.png');
  background-attachment: fixed;
  background-position: top;
  background-size: cover;
}

.inner {
  display: flex;
  gap: 8rem;

  @media (min-width: 0) and (max-width: mediaQueries.$bp-desktop-medium-max) {
    flex-direction: column;
    align-items: start;
  }

  &__left {
    flex: 1 1 50%;
  }

  &__right {
    flex: 1 1 50%;
  }
}

.logo {
  display: inline-block;
  margin-bottom: 2rem;
}

