@use "src/shared/styles/mediaQueries";

.inner {
  overflow: auto;
  display: flex;
  gap: 4rem;
  align-self: center;

  max-height: 80vh;

  @media (min-width: 0) and (max-width: mediaQueries.$bp-desktop-small-max) {
    display: flex;
    flex-direction: column;
  }
}
