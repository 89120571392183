@keyframes slide-left {
  0% {
    margin-left: 120%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes slide-right {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 120%;
  }
}

.container {
  overflow: hidden;

  width: 300px;
  margin-bottom: 1rem;

  background-color: white;
  box-shadow: var(--shadow-1);

  animation: slide-left .5s linear forwards;

  & p {
    margin: 0;
    padding: 1rem;
  }

  &_exit {
    animation: slide-right .5s linear forwards;
  }
}

.bar {
  height: .5rem;

  &_info {
    background-color: var(--info-main);
  }

  &_error {
    background-color: var(--error-main)
  }

  &_warning {
    background-color: var(--warning-main)
  }

  &_success {
    background-color: var(--success-main)
  }
}
