@use "src/app/styles/common/layout.module";

.inner {
  display: flex;
  gap: 1rem;
  height: 86vh;
}

.formContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
}

.formGroupContainer {
  & > div {
    margin-bottom: 1rem;
  }
}

.group {
  overflow-y: auto;
  flex-grow: 1;

  padding: 2rem 2rem 1rem;

  background-color: var(--color-common-white);
  border: 1px solid var(--color-custom-main-border);
  border-radius: var(--border-radius);
}

.buttonsWrapper {
  @include layout.paper-color;

  & > button:not(:last-child) {
    margin-right: 1rem;
  }
}

.error {
  color: var(--error-main);
}
