.container {
  font-family: Ubuntu, sans-serif;

  &_dark {
    color: var(--color-primary-main)
  }

  &_light {
    color: var(--color-common-white)
  }

  &_size {
    &_1 {
      font-size: 2.4rem;
    }

    &_2 {
      font-size: 2rem;
    }

    &_3 {
      font-size: 1.6rem;
    }

    &_4 {
      font-size: 1.2rem;
    }

    &_5 {
      font-size: .9rem;
    }

    &_6 {
      font-size: .8rem;
    }
  }
}
