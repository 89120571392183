@mixin container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
}

@mixin inner {
  display: flex;
  flex: 1 0 auto;
  padding: 2rem;
}

@mixin grid-column {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  gap: 1rem;

  width: 100%;
}

@mixin grid-row {
  display: flex;
  gap: 1rem;
  width: 100%;
}

@mixin preloader-wrapper {
  display: flex;
  flex: 1 0 100%;
  align-items: center;
  justify-content: center;
}

// * Paper
@mixin paper {
  width: 100%;
  padding: 1rem;
  background-color: white;
  border-radius: var(--border-radius);
}

@mixin paper-bw {
  @include paper;

  background-color: var(--color-common-white);
  border: 1px solid var(--color-grey-200);
}

@mixin paper-color {
  @include paper;

  background-color: var(--color-common-white);
  border: 1px solid var(--color-custom-main-border);
}

// * Card
@mixin card {
  background-color: var(--color-common-white);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-4);
}

@mixin card-regular {
  @include card;

  padding: 2rem;
}

@mixin card-thick {
  @include card;

  padding: 4rem;
}

// * Button wrappers
@mixin button-wrapper-column {
  display: flex;
  flex-direction: column;

  & button, a {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }
}

@mixin button-wrapper-row {
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: .5rem;
  }
}
