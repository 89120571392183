.container {
  padding: .4rem 2rem;
  border-radius: 30px;
}

.inner {
  display: flex;
  align-items: center;

  & > span {
    margin-left: 1rem;
  }
}
