@use 'normalize';

:root {
  // * Color
  --color-primary-main: #00356B;
  --color-primary-light: #0058ad;
  --color-primary-dark: #002952;
  --color-grey-50: #fafafa;
  --color-grey-100: #f5f5f5;
  --color-grey-200: #eee;
  --color-grey-300: #e0e0e0;
  --color-grey-400: #bdbdbd;
  --color-grey-500: #9e9e9e;
  --color-grey-600: #757575;
  --color-grey-700: #616161;
  --color-grey-800: #424242;
  --color-grey-900: #212121;
  --color-grey-a100: rgb(0 0 0 / 10%);
  --color-grey-a200: rgb(0 0 0 / 20%);
  --color-grey-a400: rgb(0 0 0 / 40%);
  --color-grey-a700: rgb(0 0 0 / 70%);

  // Common
  --color-common-white: #FFF;
  --color-common-black: #000;

  // Status
  --status-good: #c0ffd3;
  --status-bad: #FFD9D9;
  --status-empty: #E5E8F2;
  --info-light: #4fc3f7;
  --info-main: #29b6f6;
  --info-dark: #0288d1;
  --error-light: #e57373;
  --error-main: #f44336;
  --error-dark: #d32f2f;
  --warning-light: #ffb74d;
  --warning-main: #ffa726;
  --warning-dark: #f57c00;
  --success-light: #81c784;
  --success-main: #66bb6a;
  --success-dark: #388e3c;
  --color-custom-main-border: #ABBDD9;
  --color-custom-main-pale: #E5EDF5;
  --color-custom-main-light: #D0DEF5;
  --color-custom-app-background: #f4f6f8;

  // * Shadows
  --shadow-0: none;
  --shadow-1: 0px 0px 20px 1px rgb(0 0 0 / 10%);
  --shadow-2: 0px 0px 20px 2px rgb(0 0 0 / 20%);
  --shadow-3: 0px 0px 20px 3px rgb(0 0 0 / 20%);
  --shadow-4: 0px 0px 20px 4px rgb(0 0 0 / 20%);
  --shadow-5: 0px 0px 20px 5px rgb(0 0 0 / 20%);
  --shadow-6: 0px 0px 20px 6px rgb(0 0 0 / 20%);
  --shadow-7: 0px 0px 20px 7px rgb(0 0 0 / 20%);
  --shadow-8: 0px 0px 20px 8px rgb(0 0 0 / 20%);
  --shadow-9: 0px 0px 20px 9px rgb(0 0 0 / 20%);
  --shadow-10: 0px 0px 20px 10px rgb(0 0 0 / 20%);
  --shadow-11: 0px 0px 20px 11px rgb(0 0 0 / 20%);
  --shadow-12: 0px 0px 20px 12px rgb(0 0 0 / 20%);
  --shadow-13: 0px 0px 20px 13px rgb(0 0 0 / 20%);
  --shadow-14: 0px 0px 20px 14px rgb(0 0 0 / 20%);
  --shadow-15: 0px 0px 20px 15px rgb(0 0 0 / 20%);
  --shadow-16: 0px 0px 20px 16px rgb(0 0 0 / 20%);
  --shadow-17: 0px 0px 20px 17px rgb(0 0 0 / 20%);
  --shadow-18: 0px 0px 20px 18px rgb(0 0 0 / 20%);
  --shadow-19: 0px 0px 20px 19px rgb(0 0 0 / 20%);
  --shadow-20: 0px 0px 20px 20px rgb(0 0 0 / 20%);
  --shadow-21: 0px 0px 20px 21px rgb(0 0 0 / 20%);
  --shadow-22: 0px 0px 20px 22px rgb(0 0 0 / 20%);
  --shadow-23: 0px 0px 20px 23px rgb(0 0 0 / 20%);
  --shadow-24: 0px 0px 20px 24px rgb(0 0 0 / 20%);

  // * layout
  --border-radius: .25rem;

  // * z-index
  --z-index-input: 100;
  --z-index-header: 200;
  --z-index-modal: 300;
  --z-index-floating: 400;
  --z-index-notification: 500;
  --z-index-tooltip: 600;

  // * Charts & ratings
  --rating-healthy: #A3E5B2;
  --rating-healthy-bg: #e8ffe8;
  --rating-balanced: #FFEAA0;
  --rating-balanced-bg: #fffbef;
  --rating-poor: #FFD2D2;
  --rating-poor-bg: #fff1f1;
  --gender-male: #ffbe80;
  --gender-female: #A3E5B2;
  --gender-other: #7f9dff;
  --race-middle-eastern: #A3E5B2;
  --race-hispanic: #de7a7a;
  --race-black: #FFC165;
  --race-asian: #c982f6;
  --race-caucasian: #A4C0FF;
  --race-indigenous: #ab8169;

  // * Bar indicator
  --bar-indicator-primary: #2B69F0;
  --bar-indicator-secondary: #A4C0FF;

  // * Company form
  --company-form-field-width: 10rem;
}

@font-face {
  font-family: Arial;
  src: url("../../app/fonts/ArialMT.ttf") format('truetype');
}

@font-face {
  font-family: Ubuntu;
  font-weight: 600;
  src: url("../../app/fonts/Ubuntu-Bold.ttf") format('truetype')
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: Arial, sans-serif;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 600;
  white-space: pre-wrap;
}

h1 {
  font-size: 1.6rem;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 1.2rem;
}

h4, h5, h6 {
  font-size: 1rem
}

p {
  white-space: pre-line;
}

::-webkit-scrollbar {
  width: .5rem;
  height: .5rem;
}

::-webkit-scrollbar-track {
  background: var(--color-custom-main-pale);
}

::-webkit-scrollbar-thumb {
  background: var(--color-primary-main);
  border-radius: var(--border-radius);
}
