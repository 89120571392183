@use "src/app/styles/common/layout.module";

.container {
  @include layout.card;

  position: sticky;
  z-index: 100;
  top: 16.5rem;

  flex: 0 1 auto;

  padding: 1rem;
}

.icon {
  margin-bottom: .5rem;
}

.menuItems {
  & > div:not(:last-child) {
    margin-bottom: .4rem;
  }
}
