.container {
  font-weight: bold;

  &_title {
    font-size: 3rem;
  }

  &_paragraph {
    font-size: 1rem;
  }

  &_dark {
    color: var(--color-primary-main);
  }

  &_light {
    color: var(--color-grey-50)
  }
}
