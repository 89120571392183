.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdfViewer {
  width: 494.87px;
  height: 494.87px;
  margin-bottom: 3rem;
}
