.inner {
  display: flex;
  align-items: center;

  & svg {
    fill: var(--color-grey-700);

    &:hover {
      fill: var(--color-primary-main);
    }
  }
}
