@use "src/pages/company/ui/dataPointGenerator/fields/_styles/localMixins";

.fieldWrapper {
	@include localMixins.field-wrapper;
}

.input {
	display: none;
}

.textField {
	width: 15rem;
}

.error {
	@include localMixins.error;
}
